// Angular Files
import { BrowserModule, Title } from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { A11yModule } from '@angular/cdk/a11y';

// Angular Material Files
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

// Root App Component Files
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Teller Online Files
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { AuthService, SessionExpiryEnum } from "./core/services";
import { environment } from 'apps/admin-portal/src/environments/environment'
import { config } from "apps/admin-portal/src/config/config";

// Teller Online Library Files
import { TellerOnlineIconsModule, TellerOnlineIconsService } from "teller-online-libraries/icons";
import {
    API_BASE_URL,
    TellerOnlineAppService,
    TellerOnlineCoreModule,
    TellerOnlineErrorHandlerService,
    TellerOnlineSiteMetadataService,
    TellerOnlineXsrfService
} from "teller-online-libraries/core";
import { TellerOnlineSharedModule } from "teller-online-libraries/shared";

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: "registerImmediately" }),
        CoreModule,
        SharedModule,
        TellerOnlineIconsModule,
        TellerOnlineSharedModule,
        TellerOnlineCoreModule,
        BrowserAnimationsModule,
        // Angular Material
        MatLegacyButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatToolbarModule,
        A11yModule
    ],
    providers: [
        Title,
        TellerOnlineIconsService,
        { provide: ErrorHandler, useClass: TellerOnlineErrorHandlerService },
        { provide: API_BASE_URL, useValue: config.adminPortalApiUrl },
        { provide: APP_INITIALIZER, useFactory: loadRequiredServices, deps: [
            TellerOnlineAppService,
            TellerOnlineSiteMetadataService,
            AuthService,
            TellerOnlineErrorHandlerService,
            TellerOnlineXsrfService,
            API_BASE_URL], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TellerOnlineXsrfService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TellerOnlineAppService, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function loadRequiredServices(
    appService: TellerOnlineAppService,
    siteMetadataService: TellerOnlineSiteMetadataService,
    authService: AuthService,
    errorHandlerService: TellerOnlineErrorHandlerService,
    xsrfService: TellerOnlineXsrfService,
    apiBaseUrl: string) {
    return () => {
        return new Promise((resolve) => {
            // initialize all the shared services with the correct base url (because the injection token will not have run yet at this point)
            errorHandlerService.init(environment, apiBaseUrl);
            siteMetadataService.init(apiBaseUrl);
            xsrfService.init(apiBaseUrl);
            /* Immediately check if we need to reload the page,
            because we are immediatelly registering the service worker this should finish before the other requests finish so we shouldn't see any blips (double load)
            if there is a blip though, the speed should be so fast that it shouldn't be a big deal*/
            appService.checkForUpdates();
            // setup which pages do not use rail navigation so we can properly adjust the layout of the app
            appService.setNonRailPages(["/dashboard", "/sign-in"]);

            //Check for AD Config
            authService.getAdConfig();

            // After we've checked (not necessarily finished applying an update if there was one)
            siteMetadataService.load().then(() => {
                getUser(authService, resolve);
            });
        });
    }
}

function getUser(authService: AuthService, resolve) {
    // Get the current user, if no user is returned we know we arne't authenticated
    authService.getCurrentUser().finally(() => {
        if (authService.isSessionExpired() == SessionExpiryEnum.Expired) {
            authService.localSignOut();
        }
        accesibility_contentLoaded();
        resolve(true);
    });
}

function accesibility_contentLoaded() {
    document.querySelector('body').setAttribute('aria-busy', 'false');
}
