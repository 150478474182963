// Angular Files
import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';

// Angular Material Files
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';

// Other External Files

// Component Files

// Teller Online Files

// Teller Online Library Files
import { TellerOnlineIconsModule } from "teller-online-libraries/icons";
import {
    PaymentStatusEnum,
    PaymentStatusEnumHelpers
} from 'teller-online-libraries/core';

@Component({
    selector: 'app-status-help-dialog',
    templateUrl: './status-help-dialog.component.html',
    styleUrls: ['./status-help-dialog.component.scss'],
    host: {
        class: 'status-help-dialog'
    }
})
export class StatusHelpDialogComponent {

    // Public variables
    public statusInfo;

    // Private variables

    // private CartStatusEnumHelpers


    // Subscriptions

    constructor() {
        PaymentStatusEnumHelpers.PaymentStatusEnumHelpers();
        this.statusInfo = this._getStatusInfo();
    }

    private _getStatusInfo() {
        const entries = [];

        for (let status of Object.values(PaymentStatusEnum)) {
            entries.push({name: status, info: PaymentStatusEnumHelpers.getDescription(status)});
        }

        return entries;
    }

}
@NgModule({
    imports: [
        CommonModule,
        TellerOnlineIconsModule,
        MatLegacyButtonModule,
        MatLegacyDialogModule,
        MatIconModule
    ],
    declarations: [ StatusHelpDialogComponent ],
    exports: [ StatusHelpDialogComponent ]
})
export class StatusHelpDialogModule { }
