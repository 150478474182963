// Angular Files
import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

// Angular Material Files
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

// Teller Online Files
import { AuthService, SignInRequestModel, TODAY_FILTER } from 'apps/admin-portal/src/app/core/services';
import { PaymentsSearchOptionsEnumDto } from 'apps/admin-portal/src/app/core/api/AdminPortalApiClients';

// Teller Online Library Files
import { TellerOnlineAppService, SearchFilterOption, SearchFilterModel, SearchSettingsModel } from 'teller-online-libraries/core';
import { TellerOnlineMessageService, TellerOnlineValidationService, TellerOnlineWindowService } from 'teller-online-libraries/shared';

@Component({
    selector: 'app-sign-in-form',
    templateUrl: './sign-in-form.component.html',
    styleUrls: ['./sign-in-form.component.scss'],
    host: {
        class: 'sign-in-form'
    }
})
export class SignInFormComponent implements OnInit{
    // Declare @Input variables
    @Input() useSubmitButton: boolean = false;
    @Input() userIdReadOnly: boolean = false;
    @Input() loaderOnSubmit: boolean = true;
    @Input() showSignOut: boolean = false;

    public signInRequest: SignInRequestModel = new SignInRequestModel();
    public signingOut: boolean = false;
    public signingIn: boolean = false;

    public get loading () {
        return this.signingOut ||  this.signingIn;
    }

    constructor(
        private appService: TellerOnlineAppService,
        private messageService: TellerOnlineMessageService,
        private router: Router,
        public windowService: TellerOnlineWindowService,
        public authService: AuthService,
        public validationService: TellerOnlineValidationService
    ) {}

    ngOnInit(): void {
        if(this.userIdReadOnly) {
            this.signInRequest.userId = this.windowService.getLocalStorageItem("tellerOnlineAdminPortalUId");
        }
    }

    async onSubmit_signIn(form: NgForm) {
        if (!this.loaderOnSubmit) this.signingIn = true;

        // If AD is enabled there is no form to validate, but we
        // want to force the user to validate their credentials
        // again so we need to force a logout and redirect to
        // third party authentication service
        if(this.authService.adConfigModel.isAdEnabled && !this.authService.tellerLogon) {
            this.authService.adSignOut();
        } else {

            if (!this.validationService.runValidation(form)) {
                this.signingIn = false;
                return false;
            }

            if(this.loaderOnSubmit) this.appService.triggerPageLoading();

            try {
                let response = await this.authService.signIn(this.signInRequest);
                if(response.success) {
                    if (this.appService.currentPage('/sign-in') || this.appService.currentPage('/', true)) {
                        this.router.navigate(["/dashboard"]);
                    }
                } else {
                    this.messageService.notification("We were unable to log you in based on the information you entered.", "error", 5000);
                }
            } finally {
                if(this.loaderOnSubmit) {
                    this.appService.finishPageLoading();

                } else {
                    this.signingIn = false;
                }
            }
        }
    }

    async onClick_signOut(form: NgForm) {
        // If AD is enabled there is no form to reset
        if(!this.authService.adConfigModel.isAdEnabled || this.authService.tellerLogon) {
            form.resetForm();
        }
        this.signingOut = true;
        await this.authService.signOut();
        this.signingOut = false;
    }
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatLegacyButtonModule,
        MatLegacyInputModule,
        MatLegacyFormFieldModule,
        MatLegacyProgressSpinnerModule
    ],
    declarations: [ SignInFormComponent ],
    exports: [ SignInFormComponent ]
})
export class SignInFormModule { }