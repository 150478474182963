import { HttpResponse, HttpResponseBase, HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";

import { TellerOnlineWindowService } from "teller-online-libraries/shared";

export const BROWSER_TRACING_HEADER = 'Btid';
export const REQUEST_TRACING_HEADER = 'Tid';

interface IOptionsWithHeaders {
    headers: HttpHeaders;
}

export class TellerOnlineApiClientBase {
    private windowService: TellerOnlineWindowService;

    constructor(windowService: TellerOnlineWindowService) {
        this.windowService = windowService;
    }

    /**
     * Adds tracking headers to help trace requests in the logs
     * @param options the default options to be used in http client
     * @returns options with tracking headers
     */
    transformOptions<T extends IOptionsWithHeaders>(options: T): Promise<T> {
        // Create a persistent trace id if one does not exist
        let userTraceId = this.windowService.getLocalStorageItem(BROWSER_TRACING_HEADER)
        if (!userTraceId) {
            userTraceId = crypto.randomUUID();
            this.windowService.setLocalStorageItem(BROWSER_TRACING_HEADER, userTraceId);
        }
        options.headers = options.headers
            // use a persist trace id to tie multiple requests together
            .set(BROWSER_TRACING_HEADER, userTraceId)
            // every request gets a unique trace id
            .set(REQUEST_TRACING_HEADER, crypto.randomUUID());

        return Promise.resolve(options);
    }

    /**
     * Checks response for malformed aws timeouts. In the case of an aws timeout, a 504 is sent without the expected ErrorDto object in the body.
     * This detects that case and replaces the response with one containing an ErrorDto object so that the error is not silently swallowed.
     * @param url api url
     * @param response original response coming from the api
     * @param processResponse A transforming function that expects proper dto responses
     * @returns The properly transformed response to <T>
     */
    transformResult<T>(url: string, response: HttpResponseBase, processResponse: (r: HttpResponse<any>) => Observable<T>): Observable<T> {
        if (response.status === 504) {
            const errorDto = {
                errorMessage: "This action took too long to complete. Please try again.",
                errorNumber: `S-${Math.floor(Math.random() * 100000)}`,
                errorDef: "TimeoutFromApiClient"
            };
            throw errorDto;
        }
        return processResponse(<any>response)
    }
}
