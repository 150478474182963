// Angular Files
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Angular Material Files
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

// Module Files
import { HeaderComponent } from './components/header/header.component';

// Teller Online Files
import { SharedModule } from 'apps/admin-portal/src/app/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule, // Used by the NavMenuComponent
        SharedModule,
        MatToolbarModule,
        MatIconModule,
        MatLegacyMenuModule,
        MatLegacyButtonModule
    ],
    declarations: [HeaderComponent],
    exports: [HeaderComponent]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
 }