// Angular Files
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";

// Teller Online Files
import { CoreModule } from "apps/admin-portal/src/app/core/core.module";

// Teller Online Library Files
import { TellerOnlineSiteMetadataService } from "teller-online-libraries/core";

@Injectable({
    providedIn: CoreModule
})
export class PortalFeatureGuardService  {
    constructor(private router: Router, private siteMetadataService: TellerOnlineSiteMetadataService) { }
    
    canActivate(route: ActivatedRouteSnapshot): boolean {
        // urls that are tied to authentication being enabled
        const userUrls = ["user", "users", "users/"];
        // If we don't have authentication enabled, you can't view user details
        if (!this.siteMetadataService.authenticationEnabled && userUrls.includes(route.routeConfig.path)) {
            this.router.navigate(['/']);
            return false;
        }

        return true;
    }
}
