// Angular Files
import { CommonModule } from '@angular/common';
import { Component, NgModule, Inject } from '@angular/core';

// Angular Material Files
import { MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';

// Teller Online Files
import { SignInFormModule } from 'apps/admin-portal/src/app/shared/components/sign-in-form/sign-in-form.component';
import { AuthService } from 'apps/admin-portal/src/app/core/services';

// Teller Online Library Files
import { TellerOnlineIconsModule } from "teller-online-libraries/icons";
import { TellerOnlineMessageService } from 'teller-online-libraries/shared';
import { TellerOnlineAppService } from 'teller-online-libraries/core';

@Component({
    selector: 'app-sign-in-dialog',
    templateUrl: './sign-in-dialog.component.html',
    styleUrls: ['./sign-in-dialog.component.scss'],
    host: {
        class: 'sign-in-dialog'
    }
})
export class SignInDialogComponent {

    // Public variables

    // Private variables

    // Subscriptions

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: SignInDialogOptions,
        public dialogRef: MatLegacyDialogRef<SignInDialogComponent>,
        private messageService: TellerOnlineMessageService,
        private appService: TellerOnlineAppService,
        private authService: AuthService
    ) {}

    async onSignIn_closeModal(){
        this.messageService.notification("You have been successfully signed in.", "success", 1500);
        this.dialogRef.close(true);
    }

    async onSignOut_closeModal(){
        this.messageService.notification("You have been successfully signed out.", "success", 1500);
        this.dialogRef.close(false);
    }

}
@NgModule({
    imports: [
        CommonModule,
        TellerOnlineIconsModule,
        SignInFormModule,
        MatLegacyDialogModule
    ],
    declarations: [ SignInDialogComponent ],
    exports: [ SignInDialogComponent ]
})
export class SignInDialogModule { }

class SignInDialogOptions {
    userIdReadOnly: boolean;
}

